<template>
  <div class="app-container">
    <div class="flex-form">
      <el-form
        v-loading="loading"
        class="info-form"
        label-width="170px"
      >
        <el-form-item>
          <h2>
            View Publication
          </h2>
        </el-form-item>

        <el-form-item label="Uid">
          <div class="info-field">
            {{ publication.publicationUid }}
          </div>
        </el-form-item>

        <el-form-item label="Store">
          <div class="info-field">
            {{ publication.storeName }}
          </div>
        </el-form-item>

        <el-form-item label="Title">
          <div class="info-field text-blue">
            {{ publication.title }}
          </div>
        </el-form-item>

        <el-form-item label="Description">
          <div class="info-field text-blue">
            {{ publication.description }}
          </div>
        </el-form-item>

        <el-form-item label="Meta Title">
          <div class="info-field text-blue">
            {{ publication.metaTitle }}
          </div>
        </el-form-item>

        <el-form-item label="Meta Description">
          <div class="info-field text-blue">
            {{ publication.metaDescription }}
          </div>
        </el-form-item>

        <el-form-item label="Canonical Link">
          <div class="info-field text-blue">
            {{ publication.linkCanonical }}
          </div>
        </el-form-item>

        <el-form-item label="Date Start">
          <div class="info-field">
            {{ publication.dateStart | formatDate }}
          </div>
        </el-form-item>

        <el-form-item label="Date End">
          <div class="info-field">
            {{ publication.dateEnd | formatDate }}
          </div>
        </el-form-item>

        <el-form-item label="Active">
          <div class="info-field text-blue">
            {{ publication.isActive ? 'Yes': 'No' }}
          </div>
        </el-form-item>

        <el-form-item label="Created At">
          <div class="info-field">
            {{ publication.createdAt | formatDate }}
          </div>
        </el-form-item>

        <el-form-item label="Updated At">
          <div class="info-field">
            {{ publication.updatedAt | formatDate }}
          </div>
        </el-form-item>

        <el-form-item
          v-if="publication.parserData"
          label="Parser Data"
        >
          <json-viewer
            :value="publication.parserData"
            :expand-depth="8"
            sort
          />
        </el-form-item>

        <el-form-item
          v-if="publication.images"
          label="Images"
        >
          <el-image
            v-for="image in publication.images"
            :key="image.pageNumber"
            :src="`${image.baseUrl}/${image.relativePath}`"
          />
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import permission from '@/directive/permission';
import { getPublication } from '@/api/publication';

export default {
  name: 'PublicationView',
  directives: {
    permission,
  },
  data() {
    return {
      loading: false,
      publicationUid: null,
      publication: {},
    };
  },
  watch: {
    userId() {
      this.getStore(this.publicationUid);
    },
  },
  created() {
    this.publicationUid = this.$route.params && this.$route.params.id;
    this.getPublication(this.publicationUid);
  },
  activated() {
    this.getPublication(this.publicationUid);
  },
  methods: {
    async getPublication(publicationUid) {
      try {
        this.loading = true;
        this.store = {};

        const data = await getPublication({
          publicationUid,
        });

        this.publication = data?.publication;
        this.loading = false;
      } catch (e) {
        this.loading = false;

        this.$router.push({
          name: 'PublicationList',
        });
      }
    },
  },
};
</script>
